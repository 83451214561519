$primary: #FF6A39; //Orange
$secondary: #00C7B1; //Blue
$warning: #F1BE48; //Yellow

$font-family-sans-serif: 'Raleway', Helvetica Neue, Helvetica, Arial, sans-serif;
$headings-color: #59595B;
$body-color: #59595b;

$input-focus-border-color: #00C7B1;

// $input-btn-padding-y: 1rem;

// $btn-border-radius: 20px;
