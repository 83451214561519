/*  CSS Structure
    rem: for relating spacing to the root element (15px). Allows users to adjust font size in their browser for accessibility.
    em: to make font styles relative to one another
    px: for items we don't want to scale across browsers
*/

// Bootstrap variable overrides go here
// These variables override the Required and Optional CSS below
// When the variable overrides are used, it will no longer initialize them when it gets to the files below.
@import "./styles/bootstrap_variables";

@import "../node_modules/bootstrap/scss/bootstrap";

//https://getbootstrap.com/docs/4.3/getting-started/theming/
// Option B: Include parts of Bootstrap

// Required Bootstrap CSS
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

// // Optional Bootstrap CSS
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/grid";

//Custom CSS
// @import "custom_variables";
@import './styles/variables';
@import './styles/login';
@import './styles/typography';
@import './styles/navbar';
@import './styles/sidebar';
@import './styles/success-indicator';
@import './styles/footer';
@import './styles/forms';
@import './styles/reusuable';
@import './styles/all';
// @import "extends";
// @import "top_nav";
// @import "sidebar_nav";
// @import "main";
// @import "stat_box";
