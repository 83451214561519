footer {
  padding-top: 12vw;
}
.footer-cred {
  padding-right: 5vw;
}
@media (min-width: 767px) {
  .footer-cred {
    padding-right: 5vw;
  }
}
// .login footer {
//   padding-top: 0;
// }
