// http://mds.is/float-label-pattern/
// https://getbootstrap.com/docs/4.4/examples/floating-labels/
// https://my.guideline.com/login

.tb-form .btn {
  width: 100%;
  margin-top: 0.5rem;
}
.form-help-text {
  margin-left: 15px;
}

.form-group .form-control:focus {
  border-color: $secondary;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,199,177,.25);
}

// .form-signin {
//   width: 100%;
//   max-width: 420px;
//   padding: 15px;
//   margin: auto;
// }

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: $white;
}
.donation-amounts {
  font-family: $font-family-sans-serif;
  margin-bottom: 15px;
  font-weight: 600;
  button.btn {
    border-radius: 30px;
    padding: 8px 15vw;
    margin-bottom: 12px;
    font-weight: 600;
    &.active {
      color: $white;
    }
    &:hover {
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
    }
  }
}

.StripeElement .Input {
  border: 1px solid red !important;
}
.StripeElement {
  border: 1px solid $white-off;
  border-radius: 20px;
}
.StripeElement.StripeElement input::placeholder {
  color: $orange;
}
.StripeElement.StripeElement {
  border: 1px solid $white-off;
  border-radius: 20px;
  padding: 10px 8px 10px 20px;
  width: 100%;
  margin-bottom: 12px;
  color: $body-color;
  font-weight: 400;
}
.form-group .form-control{
  color: $body-color;
}
.StripeElement.StripeElement::placeholder {
  color: $body-color;
  font-weight: 400;
}
input::placeholder,
.StripeElement .InputElement.is-empty {
  color: $body-color;
  font-size: 0.8em;
  padding: 5px 8px 5px 10px;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

input.tb-other-amt {
  border-radius: 30px;
  font-weight: 800;
  margin-bottom: 15px;
  border: 1px solid $warning;
  color: $warning;
  text-align: center;
  font-size: 1.3rem;
  font-family: $font-family-sans-serif;
  &.active {
    color: $white;
    background-color: $warning;
  }
}
input.tb-other-amt:focus {
  box-shadow: 0 0 0 0.2rem rgba(255,106,57,.25);
}

.form-group input.tb-other-amt {
  padding: 25px 0;
  &:focus {
    border: 1px solid $warning;
    box-shadow: 0 0 0 0.2rem rgba(241,190,72,.25);
  }
  &::placeholder {
    color: $warning;
  }
}
input.tb-other-amt::placeholder {
  font-weight: 600;
  font-size: 0.9em;
  color: $primary;
  font-family: sans-serif;
}
.form-group > input,
.form-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-group > label {
  position: absolute;
  top: 0;
  left: 10px;
  display: block;
  // width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  // line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  // border: 1px solid transparent;
  // border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-group input {
  padding: 15px;
}

.form-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-group input:-ms-input-placeholder {
  color: transparent;
}

.form-group input::-ms-input-placeholder {
  color: transparent;
}

.form-group input::-moz-placeholder {
  color: transparent;
}

.form-group input::placeholder {
  color: transparent;
}

// .form-group input:not(:placeholder-shown) {
//   padding-top: 1.25rem;
//   padding-bottom: .25rem;
// }
.form-group input:not(:focus) ~ label {
  display: none;
}
.form-group input:placeholder-shown ~ label{
  display: block;
}

.form-group input:focus ~ label,
.form-group input:not(:placeholder-shown):focus ~ label {
  top: -15px;
  left: 20px;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 13px;
  color: $white;
  background-color: $secondary;
  display: inline-block;
  border-radius: 20px;
  height: 26px;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-group > label {
    display: none;
  }
  .form-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-group > label {
    display: none;
  }
  .form-group input:-ms-input-placeholder {
    color: #777;
  }
}

@media (min-width: 500px) {
  .donation-amounts {
    button.btn {
      padding: 10px 10vw;
      }
    }
}

@media (min-width: 767px) {
  .tb-form .btn {
    width: initial;
  }
  .donation-amounts {
    button.btn {
      padding: 10px 3vw;
      }
    }
}
@media (min-width: 991px) {
  .donation-amounts {
    button.btn {
      padding: 10px 3.1vw;
      }
    }
    input.tb-other-amt {
      min-height: 53px;
    }
}

@media (min-width: 1199px) {
  .donation-amounts {
    button.btn {
      padding: 10px 3.8vw;
      }
    }
}
