.divider-blue {
  height: 4px;
  background-color: $secondary;
  width: 74px;
  display: block;
  margin-bottom: 24px;
}
button.btn-secondary-outline {
  padding: 12px 45px;
  border: 1px solid $secondary;
  border-radius: 30px;
  color: $secondary;
}
.btn-outline-danger,
.btn-outline-warning,
.btn-outline-secondary {
  padding: 12px 45px;
  border-radius: 30px;
}
button.btn-primary {
  padding: 12px 80px;
  background-color: $secondary;
  border: 0;
  border-radius: 30px;
  // font-size: 1.1rem;
  &:hover {
    background-color: #219c8e;// dark blue
  }
}

.btn {
  font-size: 1.3rem;
}

.btn {
  font-size: 1.3rem;
}

.btn {
  font-size: 1.3rem;
}

@media (min-width: 767px) {
  button + button {
    margin-left: 10px;
  }
}
