.login-image {
  background-image: url('../img/trailblazer-students.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 62vw;
}
[role="main"].login {
  padding-top: 0;
}

.login .login-logo {
  padding-top: 10vw;
  width: 67%;
  margin-bottom: 25px;
}
.login-signup {
  margin-top: 15px;
  display: inline-block;
}
@media (min-width: 767px) {
  .login-image {
    height: 100vh;
    width: 100vw;
    border-left: 4px solid $primary;
    background-position: top left;
  }
  .login .login-logo {
    padding-top: 16vw;
    width: 58%;
  }
}
@media (min-width: 991px) {
  .login .login-logo {
    width: 48%;
  }
}
@media (min-width: 1400px) {
  .contain-xl-content {
    max-width: 980px;
  }
}
