body {
  height: 100vh;
  width: 100vw;
}

.form-control {
  border-radius: 1.5rem;
  height: calc(1.7em + .75rem + 2px);
  padding: .375rem 1rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
.nav-item {
  cursor: pointer;
}
.signin-altoption-text {
  color: $primary;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 150px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 68px; /* Space for fixed navbar */
  }
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

@media (min-width: 992px) {
  [role="main"] {
    padding-top: 100px; /* Space for fixed navbar */
  }
  main {
    min-height: 700px;
  }
}
