/*
 * Navbar
 */

.navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: -2px;
  background-color: $primary;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.navbar-light {
  border-bottom: 1px solid $warning;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $white;
}

.navbar-nav .dropdown-menu {
  left: 12px;
  top: 43px;
  background-color: $warning;
  border: 1px solid $warning;
  &:hover {
    background-color: darken($warning, 10%);
    border: 1px solid darken($warning, 10%);
  }
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-weight: 600;
  font-size: 1.05rem;
  text-align: center;
  .dropdown-item {
    color: $white;
    &:hover {
      background-color: darken($warning, 10%);
    }
  }
}

.navbar-toggler {
  margin: 5px;
}

.nav-item {
  padding-left: 20px;
  padding-right: 20px;
}

.icon-account {
  margin-top: 5px;
  float: left;
  padding-right: 5px;
}

.trailblazer-logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 44%;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

@media (min-width: 767px) {
  .icon-account {
    margin-left: -26px;
  }
}
@media (min-width: 992px) {
  .navbar-brand {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -2px;
  }
  .nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-nav .dropdown-menu {
    left: -33px;
    top: 47px;
  }
  .trailblazer-logo {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $primary;
    width: 68%;
  }
}
