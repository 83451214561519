@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar {
  background-color: $primary;
  // padding-top: 70px;
}

.sidebar .nav-link {
  font-weight: 500;
  color: $white;
  padding: .6rem 2.7vw;
  font-size: 1.1rem;
}

.sidebar .nav-link.active {
  background-color: rgba(255,255,255,.28);
}

@media (min-width: 767px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding-top: 65px;
  }
  .sidebar .nav-item {
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .sidebar {
    padding-top: 135px;
  }
}
