// Colors
// $warning: #7ac142;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  display: inline-block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $warning;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  margin: 0px 80px 5px 80px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $warning;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $warning;
  }
}